import axios from "axios";

const OknoEdit = ({
  setShowModal,
  vsetkyUdaje,
  setNacitava,
  handlePopUp,
  nadpisForm,
  popisForm,
  pocetZmenych,
}) => {
  const serverAdresa = process.env.REACT_APP_API_URL;

  const handleClickModal = () => {
    setShowModal(false);
  };

  async function upravitServo() {
    setNacitava(true);
    // zmazat prazdne standardne/rozsierene

    if (vsetkyUdaje.vybavenie) {
      vsetkyUdaje.vybavenie.standardne =
        vsetkyUdaje.vybavenie.standardne.filter((e) => e !== "");
      vsetkyUdaje.vybavenie.rozsirene = vsetkyUdaje.vybavenie.rozsirene.filter(
        (e) => e !== ""
      );
    }

    try {
      await axios.put(`${serverAdresa}upravitServo`, {
        vsetkyUdaje,
      });
    } catch (err) {
      console.log(err);
    }
    setShowModal(false);
    setNacitava(false);
    handlePopUp(true);
    setTimeout(() => {
      handlePopUp(false);
    }, 1500);
  }

  return (
    <div className="auth-modal" style={{ height: "330px" }}>
      <div className="close-icon" onClick={handleClickModal}>
        ✖
      </div>
      <div id="nadpis_form">{nadpisForm}</div>
      <div id="popis_form">
        {popisForm} {vsetkyUdaje.nazov}
      </div>
      {pocetZmenych && (
        <div className="popis_form">
          {parseInt(pocetZmenych) >= 0
            ? `Počet nových výnimiek: ${pocetZmenych}`
            : `Počet odstránených výnimiek: ${Math.abs(
                parseInt(pocetZmenych)
              ).toString()}`}{" "}
        </div>
      )}

      <div className="tlacitka-formular" style={{ marginTop: "200px" }}>
        <button
          className="tlacitko"
          onClick={handleClickModal}
          style={{ padding: "10px 0 10px 0", width: "180px" }}
        >
          ← Zrušiť
        </button>
        <button
          className="tlacitko"
          style={{ padding: "10px 0 10px 0", width: "180px" }}
          onClick={() => upravitServo()}
        >
          Potvrď →
        </button>
      </div>
    </div>
  );
};
export default OknoEdit;
