import axios from "axios";

const PoznamkaGlobalEdit = ({
  setShowModal,
  vsetkyVseobPoznamky,
  setNacitava,
  handlePopUp,
}) => {
  const serverAdresa = process.env.REACT_APP_API_URL;

  const handleClickModal = () => {
    setShowModal(false);
  };

  async function upravitGlobalPoznamky() {
    setNacitava(true);
    // zmazat prazdne standardne/rozsierene

    try {
      await axios.post(`${serverAdresa}updateVseobecnePoznamky`, {
        vsetkyVseobPoznamky,
      });
    } catch (err) {
      console.log(err);
    }
    setShowModal(false);
    setNacitava(false);
    handlePopUp(true);
    setTimeout(() => {
      handlePopUp(false);
    }, 1500);
  }

  return (
    <div className="auth-modal" style={{ height: "330px" }}>
      <div className="close-icon" onClick={handleClickModal}>
        ✖
      </div>
      <div id="nadpis_form">Chcete naozaj upraviť všeobecné poznámky ?</div>
      <div id="popis_form">
        Momentálny počet: {Object.keys(vsetkyVseobPoznamky).length}
      </div>

      <div className="tlacitka-formular" style={{ marginTop: "200px" }}>
        <button
          className="tlacitko"
          onClick={handleClickModal}
          style={{ padding: "10px 0 10px 0", width: "180px" }}
        >
          ← Zrušiť
        </button>
        <button
          className="tlacitko"
          style={{ padding: "10px 0 10px 0", width: "180px" }}
          onClick={() => upravitGlobalPoznamky()}
        >
          Potvrď →
        </button>
      </div>
    </div>
  );
};
export default PoznamkaGlobalEdit;
