import { useState, useEffect } from "react";
import axios from "axios";
import Loading from "../components/LoadingScreen";

import regada_picture from "../images/logoTYPOGRAPHIAwhite.svg";
import sipka from "../images/icons8-arrow.svg";
import { useLocation, useNavigate } from "react-router-dom";

import AlertDialogSlide from "../components/AlertDialogSlide";

const Slovnik = () => {
  const serverAdresa = process.env.REACT_APP_API_URL;

  const location = useLocation();
  const navigate = useNavigate();

  let zaheslovane = location.state.zaheslovane;

  const [slovnik, setSlovnik] = useState(null);
  const [SK, setSK] = useState("");
  const [EN, setEN] = useState("");
  const [RU, setRU] = useState("");

  const [mazanie, setMazanie] = useState(false);

  const [nacitava, setNacitava] = useState(false);

  const [open, setOpen] = useState(false);

  const [ktorySlovnik, setKtorySlovnik] = useState("vseob");

  async function getSlovnikVseob() {
    setNacitava(true);
    try {
      const response = await axios.get(`${serverAdresa}slovnikVseob`);
      setSlovnik(response.data);
    } catch (e) {
      console.log(e);
    }
    setNacitava(false);
  }

  async function getSlovnikPoznamky() {
    setNacitava(true);
    try {
      const response = await axios.get(`${serverAdresa}slovnikPoznamky`);
      setSlovnik(response.data);
    } catch (e) {
      console.log(e);
    }
    setNacitava(false);
  }

  function removeLastPeriod(str) {
    // Check if the last character is a period
    if (str.charAt(str.length - 1) === ".") {
      // Remove the last character using slice
      return str.slice(0, -1);
    } else {
      // If the last character is not a period, return the original string
      return str;
    }
  }

  const odstranitRiadok = (slovoNaZmazanie) => {
    let update_slovnik = { ...slovnik };
    delete update_slovnik[slovoNaZmazanie];
    setSlovnik({ ...update_slovnik });
  };

  const handleSlovnikChange = (zmeneneSlovo, SK, ktoryJazyk) => {
    let update_slovnik = { ...slovnik };
    update_slovnik[SK][ktoryJazyk] = zmeneneSlovo;
    setSlovnik(update_slovnik);
  };

  const handleSlovnikSKWordChange = (zmeneneSlovo, SK) => {
    let update_slovnik = { ...slovnik };
    update_slovnik[zmeneneSlovo] = update_slovnik[SK];
    delete update_slovnik[SK];
    setSlovnik(update_slovnik);
  };

  async function odoslatSlovnik() {
    setNacitava(true);
    try {
      const response = await axios.put(`${serverAdresa}upravitSlovnik`, {
        slovnik,
        ktorySlovnik,
      });
      const success = response.status === 200;
      if (success) {
        if (ktorySlovnik === "vseob") {
          getSlovnikVseob();
        } else if (ktorySlovnik === "poznamky") {
          getSlovnikPoznamky();
        }
        setSK("");
        setEN("");
        setRU("");
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 1500);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function pridatSlovo() {
    setNacitava(true);
    if (SK.length !== 0) {
      setSlovnik({
        ...slovnik,
        [removeLastPeriod(SK.trim())]: [
          removeLastPeriod(EN.trim()),
          removeLastPeriod(RU.trim()),
        ],
      });
      setSK("");
      setEN("");
      setRU("");
    }
    setNacitava(false);
  }

  useEffect(() => {
    if (ktorySlovnik === "vseob") {
      getSlovnikVseob();
    } else if (ktorySlovnik === "poznamky") {
      getSlovnikPoznamky();
    }
  }, [ktorySlovnik]);

  return (
    <div>
      {" "}
      {slovnik && !nacitava && (
        <div>
          <div id="headerup">
            {" "}
            <img
              id="back_header"
              src={sipka}
              alt="sipka"
              onClick={() =>
                navigate("/menu", {
                  state: {
                    zaheslovane,
                  },
                })
              }
              style={{ cursor: "pointer" }}
            ></img>
            <img
              src={regada_picture}
              id="typografia_header"
              alt="typography"
              onClick={() =>
                navigate("/menu", {
                  state: {
                    zaheslovane,
                  },
                })
              }
              style={{ cursor: "pointer" }}
            ></img>
            <div style={{ width: "60px" }}></div>
          </div>

          <AlertDialogSlide open={open} sprava="Slovník bol úspešne upravený" />

          <div
            style={{
              textAlign: "center",
              fontFamily: "CamptonBold",
              fontSize: "20px",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            Vyberte slovník
          </div>
          <div
            style={{
              display: "flex",
              gap: "20px",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <div
              className={
                ktorySlovnik === "vseob" ? "tlacitko_stlacene" : "tlacitko"
              }
              onClick={() => setKtorySlovnik("vseob")}
            >
              Všeobecný
            </div>

            <div
              className={
                ktorySlovnik === "poznamky" ? "tlacitko_stlacene" : "tlacitko"
              }
              onClick={() => setKtorySlovnik("poznamky")}
            >
              Pre poznámky
            </div>
          </div>

          <div className="slovnikyObidva">
            <div className="row nazvy_slovnik">
              <div className="element_nazov">Slovenský</div>
              <div className="element_nazov">Anglický</div>
              <div className="element_nazov">Ruský</div>
            </div>

            <div className="table-row-slovnik">
              <input
                className="table-data-slovnik"
                placeholder="sk"
                value={SK}
                onChange={(e) => setSK(e.target.value)}
              />
              <input
                className="table-data-slovnik"
                placeholder="en"
                value={EN}
                onChange={(e) => setEN(e.target.value)}
              />
              <input
                className="table-data-slovnik"
                placeholder="ru"
                value={RU}
                onChange={(e) => setRU(e.target.value)}
              />
            </div>
            <div
              className="tlacitko"
              style={{ width: "68px" }}
              onClick={() => pridatSlovo()}
            >
              Pridať +
            </div>

            <div
              style={{
                marginTop: "10px",
                width: "140px",
                textAlign: "center",
              }}
              className="tlacitko-chyba"
              onClick={() => setMazanie(!mazanie)}
            >
              {mazanie ? "Zakázať mazanie" : "Povoliť mazanie"}
            </div>

            <div id="prelozene_slova">
              Celkový počet preložených{" "}
              {ktorySlovnik === "vseob" ? "slov" : "poznámok"}:{" "}
              <b>{Object.values(slovnik).length}</b>
            </div>

            {Object.keys(slovnik)
              .reverse()
              .map((e, i) => {
                if (e !== "_id") {
                  return (
                    <div style={{ display: "flex" }}>
                      <div className="table-row-slovnik">
                        <textarea
                          className="table-data-slovnik"
                          onChange={(zmena) =>
                            handleSlovnikSKWordChange(zmena.target.value, e)
                          }
                          value={e}
                        ></textarea>
                        {slovnik[e].map((elem, iJazyka) => {
                          return (
                            <textarea
                              onChange={(zmena) =>
                                handleSlovnikChange(
                                  zmena.target.value,
                                  e,
                                  iJazyka
                                )
                              }
                              className="table-data-slovnik"
                              value={elem}
                            ></textarea>
                          );
                        })}
                      </div>
                      {mazanie && (
                        <div
                          style={{ width: "10px", height: "20px" }}
                          className="tlacitko-vymazat"
                          onClick={() => {
                            odstranitRiadok(e);
                          }}
                        >
                          x
                        </div>
                      )}
                    </div>
                  );
                }
              })}
          </div>
          <div className="fixed-bottom-right">
            <div className="tlacitko" onClick={odoslatSlovnik}>
              Upraviť
            </div>
          </div>
        </div>
      )}
      {(!slovnik || nacitava) && <Loading />}
    </div>
  );
};

export default Slovnik;
