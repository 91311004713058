import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import regada_picture from "../images/logoTYPOGRAPHIAwhite.svg";
import sipka from "../images/icons8-arrow.svg";
import Loading from "../components/LoadingScreen";

import TextField from "@mui/material/TextField";

import DocumentaciaDialog from "../components/DocumentaciaDialog";

import InfoIcon from "@mui/icons-material/Info";

import AlertDialogSlide from "../components/AlertDialogSlide";

import PoznamkaGlobalEdit from "../components/PoznamkaGlobalEdit";

import Button from "@mui/material/Button";
import MaxHeightTextarea from "../components/TextArea";

const GlobalPoznamky = () => {
  const serverAdresa = process.env.REACT_APP_API_URL;

  const location = useLocation();
  const navigate = useNavigate();

  let zaheslovane = location.state.zaheslovane;

  const prveSpustenie = useRef(false);

  const [showModal, setShowModal] = useState(false);

  const [vsetkyVseobPoznamky, setVsetkyVseobPoznamky] = useState(null);

  const [nacitava, setNacitava] = useState(false);

  const [open, setOpen] = useState(false);
  const [openDocumentacia, setOpenDocumentacia] = useState(false);

  const [koncovka, setKoncovka] = useState("");

  async function getVseobPoznamky() {
    setNacitava(true);
    try {
      const response = await axios.get(`${serverAdresa}vseobPoznamky`);
      setVsetkyVseobPoznamky(response.data[0]);
    } catch (e) {
      console.log(e);
    }
    setNacitava(false);
  }

  const handlePopUp = (e) => {
    setOpen(e);
  };

  const handleDocumentacia = (e) => {
    setOpenDocumentacia(e);
  };

  const [globalnaPoznamkaJedna, setGlobalnaPoznamkaJedna] = useState("");

  useEffect(() => {
    if (prveSpustenie.current === false) {
      getVseobPoznamky();
      return () => (prveSpustenie.current = true);
    }
  }, []);

  return (
    <>
      {!nacitava && (
        <>
          <div className={showModal ? "zoslabenie" : ""}>
            <div id="headerup">
              {" "}
              <img
                id="back_header"
                src={sipka}
                alt="sipka"
                onClick={() =>
                  navigate("/menu", {
                    state: {
                      zaheslovane,
                    },
                  })
                }
                style={{ cursor: "pointer" }}
              ></img>
              <img
                src={regada_picture}
                id="typografia_header"
                alt="typography"
                onClick={() =>
                  navigate("/menu", {
                    state: {
                      zaheslovane,
                    },
                  })
                }
                style={{ cursor: "pointer" }}
              ></img>
              <div style={{ width: "60px" }}></div>
            </div>
            <DocumentaciaDialog
              openDocumentacia={openDocumentacia}
              handleDocumentacia={handleDocumentacia}
              ktoraDocumentacia={"vseobPoznamky"}
            />

            <AlertDialogSlide
              open={open}
              sprava="Všeobecné poznámky úspešne upravené."
            />
            <div style={{ margin: "20px 0 0 20px " }}>
              {/* Nova poznamka */}
              <div>
                <div
                  style={{
                    marginBottom: "16px",
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <h2>Pridať všeobecnú poznámku</h2>
                  <InfoIcon
                    htmlColor="#005e9d"
                    onClick={() => setOpenDocumentacia(true)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Číslo"
                      variant="outlined"
                      type="text"
                      onChange={(e) =>
                        setKoncovka(e.target.value.toUpperCase())
                      }
                      value={koncovka}
                      size="small"
                      style={{
                        width: "90px",
                      }}
                      inputProps={{ maxLength: 2 }}
                    />

                    <MaxHeightTextarea
                      value={globalnaPoznamkaJedna}
                      placeholder="Zadajte poznámku"
                      onChange={(e) => {
                        setGlobalnaPoznamkaJedna(e.target.value);
                      }}
                    ></MaxHeightTextarea>

                    <Button
                      variant="outlined"
                      onClick={() => {
                        let skontrolovanaPoznamka = globalnaPoznamkaJedna;

                        skontrolovanaPoznamka = skontrolovanaPoznamka.trim();

                        if (skontrolovanaPoznamka.endsWith(".")) {
                          skontrolovanaPoznamka = skontrolovanaPoznamka.slice(
                            0,
                            -1
                          );
                        }

                        setVsetkyVseobPoznamky({
                          ...vsetkyVseobPoznamky,
                          [koncovka]: skontrolovanaPoznamka,
                        });
                        setKoncovka("");
                        setGlobalnaPoznamkaJedna("");
                      }}
                    >
                      Pridať
                    </Button>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "30px" }}>
                {vsetkyVseobPoznamky &&
                  Object.keys(vsetkyVseobPoznamky).map(
                    (cisloPoznamky, indexCislaPoznamky) => {
                      return (
                        <div key={cisloPoznamky + indexCislaPoznamky}>
                          <div>
                            <b>{cisloPoznamky})</b>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "start",
                              marginRight: "15px",
                              marginBottom: "15px",
                            }}
                          >
                            <textarea
                              value={vsetkyVseobPoznamky[cisloPoznamky]}
                              style={{ all: "unset" }}
                              onChange={(e) => {
                                setVsetkyVseobPoznamky({
                                  ...vsetkyVseobPoznamky,
                                  [cisloPoznamky]: e.target.value,
                                });
                              }}
                            ></textarea>
                            <button
                              className="tlacitko-vymazat"
                              onClick={() => {
                                setVsetkyVseobPoznamky((current) => {
                                  const copy = { ...current };

                                  delete copy[cisloPoznamky];

                                  return copy;
                                });
                              }}
                            >
                              x
                            </button>
                          </div>
                        </div>
                      );
                    }
                  )}
              </div>
              <div className="fixed-bottom-right">
                <div className="tlacitko" onClick={() => setShowModal(true)}>
                  Upraviť
                </div>
              </div>
            </div>
          </div>

          {showModal && (
            <PoznamkaGlobalEdit
              setShowModal={setShowModal}
              setNacitava={setNacitava}
              handlePopUp={handlePopUp}
              vsetkyVseobPoznamky={vsetkyVseobPoznamky}
            />
          )}
        </>
      )}

      {nacitava && <Loading />}
    </>
  );
};

export default GlobalPoznamky;
