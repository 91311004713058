import { useState, useEffect } from "react";
import axios from "axios";
import Loading from "../components/LoadingScreen";
import { useParams } from "react-router-dom";

import regada_picture from "../images/logoTYPOGRAPHIAwhite.svg";
import sipka from "../images/icons8-arrow.svg";
import { useLocation, useNavigate } from "react-router-dom";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import AlertDialogSlide from "../components/AlertDialogSlide";

import MaxHeightTextarea from "../components/TextArea";

const Poznamky = () => {
  const serverAdresa = process.env.REACT_APP_API_URL;

  const { typove } = useParams();

  const navigate = useNavigate();

  let zaheslovane = false;

  // let zaheslovane = false;

  const [vsetkyUdaje, setVsetkyUdaje] = useState(null);
  const [vsetkyServopohony, setVsetkyServopohony] = useState(null);

  const [open, setOpen] = useState(false);
  const [nacitava, setNacitava] = useState(false);

  const [error, setError] = useState(null);

  const [nazovPoznamky, setNazovPoznamky] = useState("");
  const [popisPoznamky, setPopisPoznamky] = useState("");

  const [poznamka, setPoznamka] = useState({});

  // const handleBeforeUnload = (event) => {
  //   event.preventDefault();

  //   const confirmationMessage = "Ste si istý že chcete opustiť stránku ?";

  //   event.returnValue = confirmationMessage;

  //   return confirmationMessage;
  // };

  async function getAllServa() {
    try {
      const response = await axios.get(`${serverAdresa}vsetko`);
      setVsetkyServopohony(response.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function upravitServo() {
    setNacitava(true);
    try {
      await axios.put(`${serverAdresa}upravitPoznamky`, {
        typove,
        poznamka,
      });
    } catch (err) {
      console.log(err);
    }
    setNacitava(false);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 1500);
  }

  async function getServo() {
    try {
      const response = await axios.get(`${serverAdresa}standard`, {
        params: { typove },
      });
      setVsetkyUdaje(response.data);
      if (response.data.poznamka) {
        setPoznamka(response.data.poznamka);
      } else {
        setPoznamka({});
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getServo();
  }, [typove]);

  useEffect(() => {
    getAllServa();
  }, []);

  // useEffect(() => {
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  const novaPoznamka = () => {
    if (!poznamka.hasOwnProperty(nazovPoznamky)) {
      if (popisPoznamky.slice(0, 6).includes(")")) {
        let indexZatvorky = popisPoznamky.indexOf(")");

        let autoNazov = popisPoznamky.substring(0, indexZatvorky).trim();
        let autoPopis = popisPoznamky.substring(indexZatvorky + 1).trim();

        if (autoPopis.endsWith(".")) {
          autoPopis = autoPopis.slice(0, -1);
        }

        autoPopis = autoPopis.replace(/\n/g, " ");

        setPoznamka({
          ...poznamka,
          [autoNazov]: autoPopis,
        });
        setError(null);
        setNazovPoznamky("");
        setPopisPoznamky("");
      } else if (nazovPoznamky.length > 0 && popisPoznamky.length > 0) {
        let skontrolovanaPoznamka = popisPoznamky;

        skontrolovanaPoznamka = skontrolovanaPoznamka.trim();

        if (skontrolovanaPoznamka.endsWith(".")) {
          skontrolovanaPoznamka = skontrolovanaPoznamka.slice(0, -1);
        }

        skontrolovanaPoznamka = skontrolovanaPoznamka.replace(/\n/g, " ");

        setPoznamka({
          ...poznamka,
          [nazovPoznamky]: skontrolovanaPoznamka,
        });
        setError(null);
        setNazovPoznamky("");
        setPopisPoznamky("");
      }
      setError(null);
    } else {
      setError("Poznámka " + nazovPoznamky + " už je pridaná");
      setNazovPoznamky("");
      setPopisPoznamky("");
    }
  };

  return (
    <>
      {vsetkyUdaje && vsetkyServopohony && !nacitava && (
        <>
          <div id="headerup">
            {" "}
            <img
              id="back_header"
              src={sipka}
              alt="sipka"
              onClick={() =>
                navigate("/menu", {
                  state: {
                    zaheslovane,
                  },
                })
              }
              style={{ cursor: "pointer" }}
            ></img>
            <img
              src={regada_picture}
              id="typografia_header"
              alt="typography"
              onClick={() =>
                navigate("/menu", {
                  state: {
                    zaheslovane,
                  },
                })
              }
              style={{ cursor: "pointer" }}
            ></img>
            <div style={{ width: "60px" }}></div>
          </div>

          <AlertDialogSlide
            open={open}
            sprava="Poznámky boli úspešne upravené"
          />

          <div className="obalena_tab1">
            <div className="vynimky-margin">
              <div className="nazov_obrazok">
                <div>
                  <h1 id="nazov">{vsetkyUdaje.nazov}</h1>
                  <div id="vybavenia_obvidva">
                    <div>
                      <h2 className="vybavenia_nazvy">Pridávanie poznámok</h2>
                    </div>
                  </div>
                </div>
                <img
                  className="hlavny_obrazok"
                  src={`https://konfigurator.regada.sk/obrazkyVsetky/servopohony/${vsetkyUdaje.kod}.png`}
                  alt={vsetkyUdaje.nazov}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  marginTop: "20px",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Číslo poznamky"
                  variant="outlined"
                  type="number"
                  onChange={(e) => setNazovPoznamky(e.target.value)}
                  value={nazovPoznamky}
                  size="small"
                  style={{
                    width: "172px",
                    marginTop: "10px",
                  }}
                />

                <div>Zadajte popis poznámky:</div>

                <MaxHeightTextarea
                  onChange={(e) => setPopisPoznamky(e.target.value)}
                  value={popisPoznamky}
                  placeholder="..."
                ></MaxHeightTextarea>
                {error}
                <Button
                  onClick={novaPoznamka}
                  variant="outlined"
                  style={{
                    width: "200px",
                    marginBottom: "20px",
                  }}
                >
                  Pridať poznámku
                </Button>

                <h2
                  hidden={Object.keys(poznamka).length === 0 ? true : false}
                  className="kod_dole"
                >
                  Všetky poznámky:
                </h2>

                <div className="oknodole_vynimky-poznamky">
                  <div
                    style={{
                      display: "flex",
                      width: "100vh",
                      flexWrap: "wrap",
                    }}
                  >
                    {Object.keys(poznamka).map((cisloPoznamky) => {
                      return (
                        <div key={cisloPoznamky + "poznamka"}>
                          <div>
                            <b>{cisloPoznamky})</b>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "start",
                              marginRight: "15px",
                              marginBottom: "15px",
                            }}
                          >
                            <textarea
                              value={poznamka[cisloPoznamky]}
                              style={{ all: "unset" }}
                              onChange={(e) => {
                                setPoznamka({
                                  ...poznamka,
                                  [cisloPoznamky]: e.target.value,
                                });
                              }}
                            ></textarea>
                            <button
                              className="tlacitko-vymazat"
                              onClick={() => {
                                setPoznamka((current) => {
                                  const copy = { ...current };

                                  delete copy[cisloPoznamky];

                                  return copy;
                                });
                              }}
                            >
                              x
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  hidden={Object.keys(poznamka).length === 0 ? true : false}
                  className="tlacitko"
                  style={{ width: "60px", marginBottom: "30px" }}
                  onClick={() => upravitServo()}
                >
                  Upraviť
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {!vsetkyUdaje && <Loading />}
      {nacitava && <Loading />}
    </>
  );
};

export default Poznamky;
