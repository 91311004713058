import axios from "axios";
import { useState, useEffect, useRef } from "react";
import regada_picture from "../images/logoTYPOGRAPHIAwhite.svg";
import sipka from "../images/icons8-arrow.svg";

import { FaSearch } from "react-icons/fa";
import sipka_find from "../images/icons8-arrow1.svg";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";

import MoreArrow from "../images/rightArrow.svg";

import { useLocation, useNavigate } from "react-router-dom";

import Loading from "../components/LoadingScreen";

const HistoriaDopytu = () => {
  const serverAdresa = process.env.REACT_APP_API_URL;

  const location = useLocation();
  const navigate = useNavigate();

  let zaheslovane = location.state.zaheslovane;

  const currentDate = new Date();
  const [dopytyZaJedenRok, setDopytyZaJedenRok] = useState(null);
  const [vsetkyRoky, setVsetkyRoky] = useState(null);
  const [rok, setRok] = useState(currentDate.getFullYear().toString());
  const [nazvyActive, setNazvyActive] = useState(["", "", "", "", "", ""]);
  const [nacitava, setNacitava] = useState(false);

  const [vyhladavanieData, setVyhladavanieData] = useState(null);

  const [searchText, setSearchText] = useState("");

  // array kde budu tie co su otvorene a na zaklade toho aj mazat odtuknutie

  const [visibleItems, setVisibleItems] = useState(20);

  const [roztuknuteCount, setRoztuknuteCount] = useState([]);

  const showMoreItems = () => {
    // Increase the number of visible items by 20
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 20);
  };

  const handleClickRow = (indexDopytu) => {
    if (roztuknuteCount.includes(indexDopytu)) {
      setRoztuknuteCount(roztuknuteCount.filter((e) => e !== indexDopytu));
    } else {
      setRoztuknuteCount([...roztuknuteCount, indexDopytu]);
    }
  };

  const prveSpustenie = useRef(false);

  function extractNumber(cisloKonfiguracie) {
    const match = cisloKonfiguracie ? cisloKonfiguracie.match(/K(\d+)$/) : null;
    return match ? match[1] : ""; // Extracted number or empty string
  }

  const shuffleSort = (vybranyNazov) => {
    const indexActive = NazvyVTabulka.indexOf(vybranyNazov);

    let nextCounters = [];
    if (nazvyActive[indexActive] === "" || nazvyActive[indexActive] === "2") {
      nextCounters = nazvyActive.map((c, i) => {
        if (i === indexActive) {
          // Increment the clicked counter
          return "1";
        } else {
          // The rest haven't changed
          return "";
        }
      });

      setNazvyActive(nextCounters);
    } else {
      nextCounters = nazvyActive.map((c, i) => {
        if (i === indexActive) {
          // Increment the clicked counter
          return "2";
        } else {
          // The rest haven't changed
          return "";
        }
      });
      setNazvyActive(nextCounters);
    }

    if (
      vybranyNazov === "Servo" ||
      vybranyNazov === "Meno" ||
      vybranyNazov === "Firma"
    ) {
      let sorted = [...dopytyZaJedenRok].sort(function (a, b) {
        // Parovanie nazvov s nazvami databazy
        var nameA =
          a[
            vybranyNazov === "Servo"
              ? "nazov"
              : vybranyNazov === "Meno"
              ? "name"
              : "firma"
          ];
        var nameB =
          b[
            vybranyNazov === "Servo"
              ? "nazov"
              : vybranyNazov === "Meno"
              ? "name"
              : "firma"
          ];

        nameA = nameA ? nameA.toUpperCase() : "";
        nameB = nameB ? nameB.toUpperCase() : "";

        if (nameA < nameB) {
          if (nextCounters[indexActive] === "1") {
            return -1;
          } else {
            return 1;
          }
        }
        if (nameA > nameB) {
          if (nextCounters[indexActive] === "1") {
            return 1;
          } else {
            return -1;
          }
        }
        // Names are equal, so no change in order
        return 0;
      });
      setVyhladavanieData(sorted);
    } else if (vybranyNazov === "Obj. kód") {
      let sorted = [...dopytyZaJedenRok].sort(function (a, b) {
        if (nextCounters[indexActive] === "1") {
          return parseInt(a.typove) - parseInt(b.typove);
        } else {
          return parseInt(b.typove) - parseInt(a.typove);
        }
      });
      setVyhladavanieData(sorted);
    } else if (vybranyNazov === "Dátum") {
      let sorted = [...dopytyZaJedenRok].sort(function (a, b) {
        const dateA = new Date(a.datum.split(".").reverse().join("-"));
        const dateB = new Date(b.datum.split(".").reverse().join("-"));

        // Compare the dates
        if (nextCounters[indexActive] === "1") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });

      setVyhladavanieData(sorted);
    } else if (vybranyNazov === "Č. Konfigurácie") {
      let sorted = [...dopytyZaJedenRok].sort(function (a, b) {
        const numberA = extractNumber(a.cisloKonfiguracie);
        const numberB = extractNumber(b.cisloKonfiguracie);

        // Convert to integers for numerical comparison
        const intNumberA = parseInt(numberA) || 0; // Default to 0 for undefined or non-numeric values
        const intNumberB = parseInt(numberB) || 0;

        // Compare based on the extracted numbers
        if (nextCounters[indexActive] === "1") {
          return intNumberA - intNumberB;
        } else {
          return intNumberB - intNumberA;
        }
      });

      setVyhladavanieData(sorted);
    }
  };

  async function getHistoria() {
    setNacitava(true);
    try {
      const response = await axios.get(`${serverAdresa}historia`, {
        params: { rok },
      });

      setDopytyZaJedenRok(response.data.vybranyRok);
      setVyhladavanieData(response.data.vybranyRok);
      if (!vsetkyRoky) {
        setVsetkyRoky(
          response.data.vsetkyRoky.sort(function (a, b) {
            return b - a;
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
    setNacitava(false);
  }

  useEffect(() => {
    if (prveSpustenie.current === false) {
      // do fetch dat aj cislovanie
      getHistoria();
      return () => (prveSpustenie.current = true);
    }
  }, []);

  useEffect(() => {
    getHistoria();
  }, [rok]);

  // useEffect(() => {}, [dopytyZaJedenRok]);

  const NazvyVTabulka = [
    "Č. Konfigurácie",
    "Servo",
    "Obj. kód",
    "Meno",
    "Firma",
    "Dátum",
  ];
  const HodnotyTabulkaMongo = [
    "cisloKonfiguracie",
    "nazov",
    "stlacene",
    "name",
    "firma",
    "datum",
  ];

  const handleSearching = (value) => {
    setSearchText(value);
    filterData(value);
  };

  const excludeColumns = ["_id", "cas", "sprava"];

  // filter records by search text
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    console.log(dopytyZaJedenRok);

    if (lowercasedValue === "") {
      // If the search text is empty, reset to the original data
      setVyhladavanieData(dopytyZaJedenRok);
    } else {
      const filteredData = dopytyZaJedenRok.filter((item) => {
        return Object.keys(item).some((key) => {
          const itemValue = item[key];
          return (
            !excludeColumns.includes(key) &&
            itemValue !== null &&
            itemValue !== undefined &&
            itemValue.toString().toLowerCase().includes(lowercasedValue)
          );
        });
      });

      setVyhladavanieData(filteredData);
    }
  };

  return (
    <>
      {vyhladavanieData && dopytyZaJedenRok && vsetkyRoky && !nacitava && (
        <div>
          <div id="headerup">
            {" "}
            <img
              id="back_header"
              src={sipka}
              alt="sipka"
              onClick={() =>
                navigate("/menu", {
                  state: {
                    zaheslovane,
                  },
                })
              }
              style={{ cursor: "pointer" }}
            ></img>
            <img
              src={regada_picture}
              id="typografia_header"
              alt="typography"
              onClick={() =>
                navigate("/menu", {
                  state: {
                    zaheslovane,
                  },
                })
              }
              style={{ cursor: "pointer" }}
            ></img>
            <div style={{ width: "60px" }}></div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "20px 40px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
                alignItems: "center",
              }}
            >
              <h1 id="nazov" style={{ flexGrow: 1, flexBasis: 0 }}>
                História dopytov ({dopytyZaJedenRok.length})
              </h1>
              <div className="input-wrapper" style={{ margin: "0" }}>
                <FaSearch id="search-icon" style={{ fontSize: "20px" }} />

                <input
                  className="cislo_objednavacie"
                  placeholder="Vyhľadávanie"
                  onChange={(e) => {
                    handleSearching(e.target.value);
                  }}
                  value={searchText}
                />
                <img
                  // onClick={handleSubmit}
                  src={sipka_find}
                  alt="sipka"
                  className="checkmark"
                />
              </div>
              <div
                style={{
                  flexGrow: 1,
                  flexBasis: 0,
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box sx={{ width: 90 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Rok</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={rok}
                      label="rok"
                      onChange={(e) => {
                        setRok(e.target.value);
                        setNazvyActive(["", "", "", "", "", ""]);
                      }}
                    >
                      {vsetkyRoky.map((e) => {
                        return <MenuItem value={e}>{e}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>

            <div className="cele_nazvy" style={{ marginBottom: "16px" }}>
              {NazvyVTabulka.map((e, i) => {
                return (
                  <div className="row-dopyt">
                    {" "}
                    <button
                      className="element_nazov_historia"
                      value={e}
                      onClick={(e) => {
                        shuffleSort(e.target.value);
                      }}
                    >
                      {nazvyActive[i] === ""
                        ? e
                        : nazvyActive[i] === "1"
                        ? e + " ▲"
                        : e + " ▼"}
                    </button>
                  </div>
                );
              })}
            </div>

            <div className="table-content" style={{ alignItems: "center" }}>
              {vyhladavanieData
                .slice(0, visibleItems)
                .map((jedenDopyt, indexJednehoDopytu) => {
                  return (
                    <div className="collapsable_row">
                      <div
                        className="table-row-historia"
                        onClick={() => {
                          handleClickRow(indexJednehoDopytu);
                        }}
                      >
                        {HodnotyTabulkaMongo.map((jednaHodnotaKluc) => {
                          if (jednaHodnotaKluc === "datum") {
                            return (
                              <Tooltip title={jedenDopyt["cas"]} arrow>
                                <div className="table-data-historia">
                                  {jedenDopyt[jednaHodnotaKluc]
                                    ? jedenDopyt[jednaHodnotaKluc]
                                    : ""}
                                </div>
                              </Tooltip>
                            );
                          }
                          return (
                            <div className="table-data-historia">
                              {jedenDopyt[jednaHodnotaKluc]
                                ? jedenDopyt[jednaHodnotaKluc]
                                : ""}
                            </div>
                          );
                        })}
                      </div>
                      {roztuknuteCount.includes(indexJednehoDopytu) && (
                        <div
                          className="additional-content"
                          onClick={() => {
                            handleClickRow(indexJednehoDopytu);
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "8px 8px 0 10px",
                              borderTop: "2px solid #005e9d",
                            }}
                          >
                            {/* Email, telefonne cislo, sprava */}
                            <div style={{ display: "flex", gap: "30px" }}>
                              <div
                                style={{ flexBasis: "200px", flexShrink: 0 }}
                              >
                                <h3>Kontaktné údaje</h3>
                                <div>
                                  {" "}
                                  Email:{" "}
                                  {jedenDopyt["email"]
                                    ? jedenDopyt["email"]
                                    : "Neuvedený"}
                                </div>
                                <div>
                                  {" "}
                                  Tel.:{" "}
                                  {jedenDopyt["tel"]
                                    ? jedenDopyt["tel"]
                                    : "Neuvedené"}
                                </div>
                              </div>

                              <div>
                                <h3>Správa</h3>
                                <div>
                                  {jedenDopyt["sprava"]
                                    ? jedenDopyt["sprava"]
                                    : "Neuvedená"}
                                </div>
                              </div>
                            </div>

                            <img
                              className="hlavny_obrazok"
                              src={`https://konfigurator.regada.sk/obrazkyVsetky/servopohony/${jedenDopyt["typove"]}.png`}
                              alt={jedenDopyt["nazov"]}
                              style={{
                                paddingLeft: "30px",
                                height: "100px",
                                marginRight: "40px",
                                marginBottom: "3px",
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              {visibleItems < vyhladavanieData.length && (
                <img
                  src={MoreArrow}
                  alt="more"
                  style={{
                    transform: "rotate(90deg)",
                    width: "20px",
                    cursor: "pointer",
                  }}
                  onClick={showMoreItems}
                ></img>
              )}
            </div>
          </div>
        </div>
      )}

      {((!dopytyZaJedenRok && !vsetkyRoky) || nacitava) && <Loading />}
    </>
  );
};

export default HistoriaDopytu;
